import 'Styles/global';

const root = document.documentElement;

/**
 * Test for a minimum set of JavaScript features required to run the fancy bits
 * of the theme.
 *
 * @return {boolean}
 */
const isLegacyBrowser = () => (
	! ( 'addEventListener' in window ) ||
	! ( 'querySelector' in document ) ||
	! ( 'localStorage' in window )
);

/**
 * Determine whether the current client supports custom events.
 *
 * @return {boolean}
 */
const supportsCustomEvents = () => {
	try {
		new CustomEvent( 'test' );
		return true;
	} catch ( e ) {
		return false;
	}
};

/**
 * Determine whether the current client supports all ES6 features used by
 * the application.
 *
 * @return {boolean}
 */
const supportsES6Features = () => (
	'Symbol' in window &&
	'Map' in window &&
	'Set' in window &&
	'includes' in String.prototype &&
	'startsWith' in String.prototype &&
	'endsWith' in String.prototype &&
	'includes' in Array.prototype &&
	'find' in Array.prototype &&
	'findIndex' in Array.prototype &&
	'from' in Array &&
	'assign' in Object &&
	'keys' in Object &&
	'values' in Object &&
	'entries' in Object
);

/**
 * Test for whether the current client supports `object-fit`. Loads a polyfill
 * if required.
 *
 * @return {Promise}
 */
const fillObjectFit = () => (
	new Promise( ( resolve, reject ) => (
		'objectFit' in root.style ?
			resolve() :
			import( /* webpackChunkName: 'polyfills.objectfit' */ 'object-fit-images' )
				.then( ( module ) => {
					const { default: objectFitImages } = module;
					objectFitImages();
					return module;
				} )
				.then( resolve )
				.catch( reject )
	) )
);

/**
 * Test for support of classList on Elements. Loads a polyfill if required
 * (IE11).
 *
 * NOTE: This polyfill will be applied for IE11 as well, as their implementation
 * of the DOMTokenList is not spec complient.
 *
 * @return {Promise}
 */
const fillClassList = () => (
	new Promise( ( resolve, reject ) => (
		'classList' in Element.prototype ?
			resolve() :
			import( /* webpackChunkName: 'polyfills.classlist' */ 'classlist-polyfill' )
				.then( resolve )
				.catch( reject )
	) )
);

/**
 * Test for CustomEvent support. Loads a polyfill if required (IE11).
 *
 * @return {Promise}
 */
const fillCustomEvent = () => (
	new Promise( ( resolve, reject ) => (
		supportsCustomEvents() ?
			resolve() :
			import( /* webpackChunkName: 'polyfills.event' */ 'custom-event-polyfill' )
				.then( resolve )
				.catch( reject )
	) )
);

/**
 * Test for support of Element.closest(). Loads a polyfill if required (IE11).
 *
 * @return {Promise}
 */
const fillElementClosest = () => (
	new Promise( ( resolve, reject ) => (
		'closest' in Element.prototype ?
			resolve() :
			import( /* webpackChunkName: 'polyfills.closest' */ 'element-closest' )
				.then( resolve )
				.catch( reject )
	) )
);

/**
 * Test for URLSearchParams support. Loads a polyfill if required (IE11).
 *
 * @return {Promise}
 */
const fillUrlSearchParams = () => (
	new Promise( ( resolve, reject ) => (
		'URLSearchParams' in window ?
			resolve() :
			import( /* webpackChunkName: 'polyfills.urlparams' */ 'url-search-params-polyfill' )
				.then( resolve )
				.catch( reject )
	) )
);

/**
 * Test for fetch support. Loads a polyfill if required (IE11, Edge 13).
 *
 * @return {Promise}
 */
const fillFetch = () => (
	new Promise( ( resolve, reject ) => (
		'fetch' in window ?
			resolve() :
			import( /* webpackChunkName: 'polyfills.fetch' */ 'whatwg-fetch' )
				.then( resolve )
				.catch( reject )
	) )
);

/**
 * Test for IntersectionObserver support. Loads a polyfill if required
 * (IE11, Edge 15, Safari).
 *
 * @return {Promise}
 */
const fillIntersectionObserver = () => (
	new Promise( ( resolve, reject ) => (
		'IntersectionObserver' in window ?
			resolve() :
			import( /* webpackChunkName: 'polyfills.observer' */ 'intersection-observer' )
				.then( resolve )
				.catch( reject )
	) )
);

/**
 * Test for es6/es7 features used within the application. Loads a set of if
 * polyfills if required.
 *
 * `Symbol`: IE11, Edge 17
 * `Map`: IE11
 * `Set`: IE11
 * `Array.from`: IE11
 * `Array.prototype.find`: IE11
 * `Array.prototype.findIndex`: IE11
 * `Array.prototype.includes`: IE11
 * `Object.keys`: IE11
 * `Object.values`: IE11
 * `Object.entries`: IE11
 * `String.prototype.inlcudes`: IE11
 * `String.prototype.startsWith`: IE11
 * `String.prototype.endsWith`: IE11
 *
 * @return {Promise}
 */
const fillCoreJS = () => (
	new Promise( ( resolve, reject ) => (
		supportsES6Features() ?
			resolve() :
			import( /* webpackChunkName: 'polyfills.corejs' */ './corejs' )
				.then( resolve )
				.catch( reject )
	) )
);

/**
 * Load required polyfills and run the application.
 *
 * @return {void}
 */
const main = () => {
	// Cutting the mustard.
	if ( isLegacyBrowser() ) {
		return;
	}

	// Add a hook that can be used within the stylesheet.
	root.classList.remove( 'no-js' );
	root.classList.add( 'js' );

	// Add missing polyfills and run application.
	Promise.all( [
		fillObjectFit(),
		fillClassList(),
		fillCustomEvent(),
		fillElementClosest(),
		fillUrlSearchParams(),
		fillFetch(),
		fillIntersectionObserver(),
		fillCoreJS(),
	] )
		.then( () => (
			import( /* webpackChunkName: 'app', webpackPreload: true */ './app' )
		) );
};

main();
